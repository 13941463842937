import React, { useState, useEffect } from 'react'

const {
  REACT_APP_API_URL,
} = process.env

function App() {
  const [comment, setComment] = useState('')
  const [status, setStatus] = useState('loading')

  async function onSubmit(e) {
    e.preventDefault()
    setStatus('thanks')
    save()
  }

  function save() {
    const value = new URLSearchParams(window.location.search).get('value')

    const payload = { comment, value: parseInt(value) || 0 }

    return fetch(`${REACT_APP_API_URL}/${window.location.pathname.substr(1)}/review`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    }).then(res => res.json()).catch(() => null)
  }

  useEffect(() => {
    try {
      save()
      setStatus('ready')
    } catch (e) {
      setStatus('error')
    }
  }, [])

  return (
    <div className="App row justify-content-center p-3 p-md-0">

      {status === 'error' && <div className="col col-sm-8 col-md-6 col-lg-5 col-xl-5">
        <h2 className="text-center mt-3">La URL no es valida</h2>
      </div>}

      {status === 'thanks' && <div className="col col-sm-8 col-md-6 col-lg-5 col-xl-5">
        <h2 className="text-center mt-3">¡Muchas Gracias!</h2>
        <p className="lead text-center p-2">Recibimos tus comentarios</p>
      </div>}

      {status === 'ready' && <div className="col col-sm-8 col-md-6 col-lg-5 col-xl-5">
        <h2 className="text-center mt-3">¡Muchas Gracias!</h2>
        <p className="lead text-center p-2">Sirve para que mejoren los profesionales y ayudar a los pacientes</p>

        <form onSubmit={onSubmit}>
          <div className="form-group">
            <textarea name="comment" placeholder="Si queres podes dejar algun comentario" id="comment" className="form-control" value={comment} onChange={e => setComment(e.target.value)}></textarea>
          </div>
          {comment.trim() && <button className="btn btn-primary btn-block btn-lg mt-4" onClick={onSubmit}>Enviar comentarios</button>}
        </form>
      </div>}
    </div>
  )
}

export default App
